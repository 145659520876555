import React from "react";
import Banners from "../components/Banners";

import LayoutFront from "../components/layout-front";
import ScrollDown from "../components/ScrollDown";
import SEO from "../components/seo";
// import Slider from "../components/Slider";

function IndexPage(props) {
  return (
    <LayoutFront>
      <SEO
        keywords={[`Timbuktku`, `Jason Diakité`, `Officiell`, `This Moment`]}
        title="Timbuktu - Officiell hemsida - Ny singel ”Bars från ett B-barn” ute nu!"
        path={props.path}
      />

      <section className="">
        <Banners />
      </section>
      <div className="hidden md:block  fixed z-100 left-0 bottom-0 p-8">
        <ScrollDown />
      </div>
    </LayoutFront>
  );
}

export default IndexPage;
