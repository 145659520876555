import React, { useState } from "react";
import { useScrollPosition } from "../utils/useScrollPosition";

const ScrollDown = () => {
  const [scroll, setScroll] = useState(0);

  useScrollPosition(function setScrollPosition({ currentPosition }) {
    setScroll(currentPosition.y);
  });
  console.log(scroll);
  return (
    <div className="opacity-50">
      {scroll > -10 && (
        <div className="mouse_scroll">
          <div className="mouse">
            <div className="wheel"></div>
          </div>
          <div>
            <span className="m_scroll_arrows unu"></span>
            <span className="m_scroll_arrows doi"></span>
            <span className="m_scroll_arrows trei"></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScrollDown;
