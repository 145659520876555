import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Banner from "./Banner";

export default function Banners() {
  const data = useStaticQuery(graphql`
    {
      allWpPush {
        nodes {
          info {
            lank
          }
          title
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    formats: JPG
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
        }
      }
    }
  `);
  const posts = data.allWpPush.nodes;
  return (
    <div className="">
      {posts.map((post, i) => {
        return <Banner key={i} post={post} />;
      })}
    </div>
  );
}
