import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Link from "../components/Link";
export default function Banner({ post }) {
  return (
    <Link to={post.info.lank}>
      <div className="w-full">
        <GatsbyImage
          image={getImage(post.featuredImage.node.localFile.childImageSharp)}
          className="w-full"
          alt="Banner"
        />
      </div>
    </Link>
  );
}
