import React from "react";

import Header from "./header";

function LayoutFront({ children }) {
  return (
    <div className="front flex flex-col font-sans text-gray-900">
      <Header />

      <main className="flex-1 w-full h-full">{children}</main>
    </div>
  );
}

export default LayoutFront;
